import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useCookie } from 'react-use';

import { colorMap } from '@constants/colors';
import ArrowRightIcon from '@icons/ArrowRight.svg';
import CloseIcon from '@icons/Close.svg';
import Link from '@components/Link';
import SanityImage from '@components/SanityImage';

import { trackAnalyticsEvent } from '@util/analytics';

import styles from './Banner.module.css';

const Banner = ({
  text,
  closeOnAction,
  actionLabel,
  actionHref,
  theme,
  id,
  image,
}) => {
  const [value, setValue] = useCookie(`cloudsmithClosedBanner:${id.current}`);

  const closeBanner = () => {
    setValue(true);
  };

  const handleAction = () => {
    trackAnalyticsEvent('websiteBannerClick', {
      bannerId: id?.current,
    });

    if (closeOnAction) {
      closeBanner();
    }
  };

  const isBannerVisible = useMemo(() => !value, [value]);

  useEffect(() => {
    if (isBannerVisible) {
      document.body.classList.add(styles.bodyWithBanner);
    } else {
      document.body.classList.remove(styles.bodyWithBanner);
    }
  }, [isBannerVisible]);

  return (
    <Link
      onClick={handleAction}
      href={actionHref}
      className={styles.root}
      style={{
        '--banner-background': colorMap[theme],
      }}
    >
      <div className={styles.content}>
        {image && (
          <div className={styles.image} role="presentation">
            <SanityImage
              asset={image.asset}
              alt=""
              width={64}
              height={64}
              sizes="64px"
            />
          </div>
        )}
        <div
          className={styles.text}
          dangerouslySetInnerHTML={{ __html: text }}
        />
        <div className={styles.action}>
          {actionLabel}
          <ArrowRightIcon className={styles.actionIcon} />
        </div>
      </div>
      <button
        aria-label="Close this banner"
        className={styles.close}
        onClick={(e) => {
          e.preventDefault();
          closeBanner(e);
        }}
      >
        <CloseIcon className={styles.closeIcon} />
      </button>
    </Link>
  );
};

Banner.propTypes = {
  actionHref: PropTypes.string.isRequired,
  actionLabel: PropTypes.string.isRequired,
  closeOnAction: PropTypes.bool,
  theme: PropTypes.string,
  text: PropTypes.string.isRequired,
  id: PropTypes.object.isRequired,
};

export default Banner;
