import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import { m as motion, LazyMotion, domAnimation } from 'framer-motion';
import { useRouter } from 'next/router';
import { useKeyPressEvent } from 'react-use';

import { colorMap } from '@constants/colors';

import ArrowLeftIcon from '@icons/ArrowLeft.svg';
import ChevronDown from '@icons/ChevronDown.svg';
import BurgerIcon from '@icons/Burger.svg';
import CloseIcon from '@icons/Close.svg';

import NavbarCardSlider from './NavbarCardSlider';
import Link from '@components/Link';
import Button from '@components/Button';

import { trackAnalyticsEvent } from '@util/analytics';
import { isExternalHref } from '@util/url';

import textStyles from '@styles/textStyles.module.css';
import scrollLockStyles from '@styles/utilities/scrollLock.module.css';
import * as styles from './Navigation.module.css';

const SecondaryNavItem = ({ item }) => {
  const router = useRouter();

  switch (item.type) {
    case 'label': {
      return <div className={textStyles.eyebrow}>{item.label}</div>;
    }
    case 'divider': {
      return (
        <div className={cn(styles.secondaryNavigationSpacer)} aria-hidden />
      );
    }
    default: {
      const isExternalLink = isExternalHref(item.path);
      return (
        <Link
          prefetch={false}
          legacyBehavior
          className={cn(styles.secondaryNavigationLink)}
          href={item.path}
        >
          <a
            aria-current={router.asPath === item.path ? 'page' : null}
            className={cn(styles.secondaryNavigationLink)}
            target={item.external ? '_blank' : null}
            rel={isExternalLink ? 'noopener noreferrer' : null}
            onClick={() => {
              trackAnalyticsEvent('link', {
                scope: 'Navbar',
                text: item.label,
                href: item.path,
              });
            }}
          >
            {item.label}
            {item.badge && (
              <span
                className={styles.secondaryNavigationBadge}
                style={{
                  '--badge-color':
                    colorMap[item.badge.color] || 'var(--color-accent-default)',
                }}
              >
                {item.badge.label}
              </span>
            )}
          </a>
        </Link>
      );
    }
  }
};

const Navigation = ({
  mainMenu,
  onMobileMenuOpen,
  onMobileMenuClose,
  onSubmenuOpen,
  onSubmenuClose,
}) => {
  const router = useRouter();

  const [showMenu, setShowMenu] = useState(false);
  const [visibleSubMenu, setVisibleSubMenu] = useState(null);

  const toggleSubMenu = (key) => {
    if (visibleSubMenu === key) {
      // If the submenu is already visible, close it
      onSubmenuClose?.();
    } else {
      onSubmenuOpen?.();
    }
    setVisibleSubMenu(visibleSubMenu === key ? null : key);
  };

  const toggleMenu = () => {
    if (showMenu) {
      closeMenu();
    } else {
      setShowMenu(true);
      onMobileMenuOpen?.();
    }
  };

  const closeMenu = () => {
    setVisibleSubMenu(null);
    onMobileMenuClose?.();
    setShowMenu(false);
    onSubmenuClose?.();
  };

  const shouldScrollLock = showMenu || !!visibleSubMenu;

  useKeyPressEvent('Escape', closeMenu);

  // Close the menu whenever a route changes
  useEffect(() => {
    const handleRouteComplete = () => {
      window.scrollTo(0, 0);
    };

    router.events.on('routeChangeStart', closeMenu);
    router.events.on('routeChangeComplete', handleRouteComplete);

    return () => {
      router.events.off('routeChangeStart', closeMenu);
      router.events.off('routeChangeComplete', handleRouteComplete);
    };
  }, [router.events]);

  // We're using a manual approach, because the useBodyScollLock hook provided
  // by react-use would break the overflow scrolling of the mobile menu on iOS
  //
  // see: https://css-tricks.com/prevent-page-scrolling-when-a-modal-is-open/
  useEffect(() => {
    if (shouldScrollLock) {
      const top = window.pageYOffset || document.documentElement.scrollTop;
      document.body.classList.add(scrollLockStyles.root);
      document.body.style.setProperty('top', `-${top}px`);
    } else {
      document.body.classList.remove(scrollLockStyles.root);
      const scrollY = document.body.style.top;
      document.body.style.top = '';
      window.scrollTo(0, parseInt(scrollY || '0') * -1);
    }
  }, [shouldScrollLock]);

  const secondaryNavigationAnimation = {
    hidden: {
      height: 0,
      opacity: 0,
      pointerEvents: 'none',
    },
    visible: {
      height: 'var(--navbar-secondary-navigation-height)',
      opacity: 1,
      pointerEvents: 'auto',
      transition: {
        delayChildren: 0.1,
        staggerChildren: 0.05,
        ease: 'easeInOut',
      },
    },
  };

  const secondaryNavigationItemAnimation = {
    hidden: {
      opacity: 0,
      y: -10,
    },
    visible: {
      opacity: 1,
      y: 0,
    },
  };

  const secondaryNavigationOverlayAnimation = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      pointerEvents: 'auto',
      transition: {
        delay: 0.125,
        duration: 0.5,
      },
    },
  };

  const secondaryNavigationContainerAnimation = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
    },
  };

  return (
    <LazyMotion features={domAnimation}>
      <motion.div
        aria-hidden
        className={cn(styles.secondaryNavigationOverlay)}
        variants={secondaryNavigationOverlayAnimation}
        initial="hidden"
        animate={visibleSubMenu ? 'visible' : 'hidden'}
        onClick={closeMenu}
      />
      <motion.div
        aria-hidden
        className={cn(styles.secondaryNavigationContainer)}
        variants={secondaryNavigationContainerAnimation}
        initial="hidden"
        animate={visibleSubMenu ? 'visible' : 'hidden'}
      />
      <nav
        className={cn(styles.primaryNavigation, {
          [styles.open]: showMenu,
          [styles.mobileMenuShowing]: showMenu,
          [styles.secondaryNavigationShowing]:
            shouldScrollLock || !!visibleSubMenu,
        })}
      >
        <ul className={cn(styles.primaryNavigationList)}>
          {mainMenu.items && mainMenu.items.length > 0 && (
            <>
              {mainMenu.items.map((item) => (
                <li
                  key={item.label}
                  className={cn(styles.primaryNavigationListItem)}
                >
                  {item.path ? (
                    <Link prefetch={false} legacyBehavior href={item.path}>
                      <a
                        aria-current={
                          router.asPath === item.path && !visibleSubMenu
                            ? 'page'
                            : null
                        }
                        className={cn(styles.primaryNavigationLink)}
                        onClick={() => {
                          trackAnalyticsEvent('link', {
                            scope: 'Navbar',
                            text: item.label,
                            href: item.path,
                          });
                        }}
                      >
                        {item.withArrowLeft && (
                          <ArrowLeftIcon
                            className={styles.primaryNavigationLinkIcon}
                          />
                        )}
                        <span className={cn(styles.primaryNavigationLinkText)}>
                          {item.label}
                        </span>
                      </a>
                    </Link>
                  ) : (
                    <>
                      <button
                        aria-current={
                          visibleSubMenu === item.label ? 'step' : null
                        }
                        className={cn(styles.primaryNavigationLink)}
                        onClick={() => toggleSubMenu(item.label)}
                      >
                        <span className={cn(styles.primaryNavigationLinkText)}>
                          {item.label}
                        </span>
                        <span className={cn(styles.primaryNavigationLinkIcon)}>
                          <ChevronDown />
                        </span>
                      </button>
                      <motion.nav
                        className={cn(styles.secondaryNavigation)}
                        variants={secondaryNavigationAnimation}
                        initial="hidden"
                        animate={
                          visibleSubMenu === item.label ? 'visible' : 'hidden'
                        }
                      >
                        <ul className={cn(styles.secondaryNavigationList)}>
                          {item.children.map((child, index) => (
                            <motion.li
                              key={index}
                              variants={secondaryNavigationItemAnimation}
                              className={cn(styles.secondaryNavigationListItem)}
                            >
                              <SecondaryNavItem item={child} />
                            </motion.li>
                          ))}
                        </ul>
                        {item.cardSliders && (
                          <motion.div
                            className={cn(styles.secondaryNavigationCardSlider)}
                            variants={secondaryNavigationItemAnimation}
                          >
                            {item.cardSliders.map((slider) => (
                              <NavbarCardSlider
                                key={slider.path}
                                cardType={slider.cardType}
                                title={slider.title}
                                path={slider.path}
                                label={slider.label}
                                cards={slider.cards}
                              />
                            ))}
                          </motion.div>
                        )}
                      </motion.nav>
                    </>
                  )}
                </li>
              ))}
            </>
          )}
          <li className={cn(styles.actions)}>
            {mainMenu.actions.map((action) => (
              <Button
                key={action.path}
                variant={action.variant ?? 'primary'}
                href={action.path}
                colorScheme={action.variant ? 'text' : null}
                trackingScope="Navbar"
                isStartFreeTrial={action.isStartFreeTrial ?? null}
              >
                {action.label}
              </Button>
            ))}
          </li>
        </ul>
      </nav>
      <div className={cn(styles.mobileMenu)}>
        {/* Semantically this should be a button, but Safari has a problem with
         * correctly rendering inlined SVG inside a button. Switching to a link
         * with proper a11y should be the acceptable fix here.
         */}
        <a
          aria-label="Open menu"
          className={cn(styles.toggleMenuButton)}
          role="button"
          onClick={toggleMenu}
        >
          {showMenu ? <CloseIcon /> : <BurgerIcon />}
        </a>
      </div>
    </LazyMotion>
  );
};

export default Navigation;
