import { PropTypes } from 'prop-types';
import cn from 'classnames';

import ArrowLink from '@components/ArrowLink';
import ArticleCard from '@components/ArticleCard';
import CardWithLogo from '@components/CardWithLogo';

import textStyles from '@styles/textStyles.module.css';
import * as styles from './NavbarCardSlider.module.css';
import { formatRoute } from '@util/routes';
import { integrationRoute } from '@util/integrations';

const NavbarCardSlider = ({ title, path, label, cards = [], cardType }) => {
  let items;

  switch (cardType) {
    case 'article':
      items = cards.map((card) => (
        <ArticleCard
          key={card.slug}
          title={card.metadata.title}
          href={card.slug}
          image={card.metadata.image}
          trackingScope="NavbarCardSlider"
        />
      ));
      break;
    default:
      items = cards.map((card) => (
        <CardWithLogo
          key={card.slug}
          title={card.metadata.name}
          href={
            card._type === 'format'
              ? formatRoute(card.slug)
              : card._type === 'integration'
              ? integrationRoute(card.slug)
              : card.slug
          }
          logo={card.metadata.logoId}
          trackingScope="NavbarCardSlider"
        />
      ));
      break;
  }

  return (
    <div className={cn(styles.root)}>
      <div className={cn(styles.header)}>
        <div className={cn(styles.title, textStyles.eyebrow)}>{title}</div>
        <ArrowLink
          href={path}
          text={label}
          variant="primary"
          trackingScope="NavbarCardSlider"
        />
      </div>
      <div className={cn(styles.cards)}>
        <div className={cn(styles.cardsWrapper)}>{items}</div>
      </div>
    </div>
  );
};

NavbarCardSlider.propTypes = {
  title: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  cards: PropTypes.array,
};

export default NavbarCardSlider;
