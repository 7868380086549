import React from 'react';
import PropTypes from 'prop-types';

import Image from '@components/Image';
import SanityImage from '@components/SanityImage';
import Link from '@components/Link';

import { trackAnalyticsEvent } from '@util/analytics';

import * as styles from './ArticleCard.module.css';

const ArticleCard = ({ title, href, image, trackingScope }) => {
  const handleClick = () => {
    if (trackingScope) {
      trackAnalyticsEvent('ArticleCard', {
        scope: trackingScope,
        text: title,
        href,
      });
    }
  };

  return (
    <Link
      prefetch={false}
      className={styles.root}
      href={href}
      onClick={handleClick}
    >
      <div className={styles.imageWrapper}>
        {image.src != null ? (
          <Image
            className={styles.image}
            src={image.src}
            alt={image.alt}
            fill
          />
        ) : (
          <SanityImage
            className={styles.image}
            asset={image.image.asset}
            alt={image.alt}
            fill
          />
        )}
      </div>
      <div className={styles.meta}>
        <div className={styles.title}>{title}</div>
        <div className={styles.link}>Read more</div>
      </div>
    </Link>
  );
};

ArticleCard.propTypes = {
  title: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
};

export default ArticleCard;
