import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import siteInformation from '@content/default.json';

import FacebookIcon from '@icons/socialMedia/facebook.svg';
import TwitterIcon from '@icons/socialMedia/twitter.svg';
import LinkedInIcon from '@icons/socialMedia/linkedin.svg';
import YoutubeIcon from '@icons/socialMedia/youtube.svg';
import GithubIcon from '@icons/socialMedia/github.svg';

import { trackAnalyticsEvent } from '@util/analytics';

import Logo from '@components/Logo';
import Link from '@components/Link';

import gridStyles from '@styles/layouts/grid.module.css';
import * as styles from './Footer.module.css';
import { isExternalHref } from '@util/url';

const Footer = ({ footerMenu }) => {
  const items = footerMenu?.items ?? [];

  const socialLinks = [
    {
      url: siteInformation.socialMedia.urls.facebook,
      icon: FacebookIcon,
      label: 'Cloudsmith on Facebook',
    },
    {
      url: siteInformation.socialMedia.urls.twitter,
      icon: TwitterIcon,
      label: 'Cloudsmith on Twitter',
    },
    {
      url: siteInformation.socialMedia.urls.linkedin,
      icon: LinkedInIcon,
      label: 'Cloudsmith on LinkedIn',
    },
    {
      url: siteInformation.socialMedia.urls.youtube,
      icon: YoutubeIcon,
      label: 'Cloudsmith on YouTube',
    },
    {
      url: siteInformation.socialMedia.urls.github,
      icon: GithubIcon,
      label: 'Cloudsmith on GitHub',
    },
  ];

  return (
    <footer className={cn(styles.root)}>
      <div className={cn(styles.topFooter, gridStyles.row)}>
        <div
          className={cn(gridStyles.column, styles.logoColumn)}
          style={{
            '--column-span-large': 6,
          }}
        >
          <Link prefetch={false} href="/" aria-label="Cloudsmith Homepage">
            <Logo className={cn(styles.logo)} />
          </Link>
        </div>
        <nav
          className={cn(gridStyles.column, styles.navPrimary)}
          style={{
            '--column-span-large': 6,
          }}
        >
          <ul className={cn(styles.navPrimaryList)}>
            {items.map((item) => {
              return (
                <li key={item.label} className={cn(styles.navPrimaryListItem)}>
                  {item.path ? (
                    <Link
                      prefetch={false}
                      href={item.path}
                      rel={
                        isExternalHref(item.path) ? 'noopener noreferrer' : null
                      }
                      onClick={() => {
                        trackAnalyticsEvent('link', {
                          scope: 'Footer',
                          text: item.label,
                          href: item.path,
                        });
                      }}
                    >
                      {item.label}
                    </Link>
                  ) : (
                    <>
                      <span>{item.label}</span>
                      <nav className={cn(styles.navSecondary)}>
                        <ul className={cn(styles.navSecondaryList)}>
                          {item.children.map((child) => (
                            <li
                              key={child.label}
                              className={cn(styles.navSecondaryListItem)}
                            >
                              <Link
                                prefetch={false}
                                href={child.path}
                                target={child.external ? '_blank' : '_self'}
                                rel={
                                  isExternalHref(child.path)
                                    ? 'noopener noreferrer'
                                    : null
                                }
                                onClick={() => {
                                  trackAnalyticsEvent('link', {
                                    scope: 'Footer',
                                    text: child.label,
                                    href: child.path,
                                  });
                                }}
                              >
                                {child.label}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </nav>
                    </>
                  )}
                </li>
              );
            })}
          </ul>
        </nav>
      </div>
      <div className={cn(styles.bottomFooter)}>
        <nav
          className={cn(gridStyles.column, styles.socialLinks)}
          style={{
            '--column-span-large': 6,
          }}
        >
          {socialLinks.map((link) => (
            <a
              key={link.url}
              href={link.url}
              rel="noopener noreferrer"
              target="_blank"
              className={cn(styles.socialLink)}
              aria-label={link.label}
              onClick={() => {
                trackAnalyticsEvent('link', {
                  scope: 'Footer',
                  text: link.label,
                  href: link.url,
                });
              }}
            >
              <link.icon className={cn(styles.socialLinkIcon)} />
            </a>
          ))}
        </nav>
        <div
          className={cn(styles.copyRight, gridStyles.column)}
          style={{
            '--column-span-large': 6,
          }}
        >
          &copy; {new Date().getFullYear()} {siteInformation.title}
        </div>
      </div>
    </footer>
  );
};

Footer.propTypes = {
  props: PropTypes.object,
};

export default Footer;
