import { useEffect, useState } from 'react';

export const useStickyHeader = ({
  offset = 40,
  idleTopOffset = '0px',
  stickyTopOffset = 'var(--nav-height)',
} = {}) => {
  const [inStickyRange, setInStickyRange] = useState(false);
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    let lastScroll = 0;

    const show = () => {
      setIsSticky(true);
      document.body.style.setProperty(
        '--layout-sticky-top-offset',
        stickyTopOffset,
      );
    };

    const hide = () => {
      setIsSticky(false);
      document.body.style.setProperty(
        '--layout-sticky-top-offset',
        idleTopOffset,
      );
    };

    const scrollListener = () => {
      const currentScroll = window.scrollY;

      if (currentScroll < offset) setInStickyRange(false);
      if (currentScroll > offset) setInStickyRange(true);

      if (currentScroll < lastScroll) {
        show();
      } else if (currentScroll > 200) {
        hide();
      } else if (currentScroll < 200) {
        show();
      }

      lastScroll = currentScroll;
    };

    window.addEventListener('scroll', scrollListener);

    return () => {
      window.removeEventListener('scroll', scrollListener);
    };
  }, [offset, idleTopOffset, stickyTopOffset]);

  return [isSticky, inStickyRange];
};
