import React, { useRef } from 'react';
import cn from 'classnames';

import { useStickyHeader } from '@util/useStickyHeader';

import Logo from '@components/Logo';

import * as styles from './Navbar.module.css';
import Link from '@components/Link';
import Navigation from '@components/Navigation';

const Navbar = ({ initialTheme, mainMenu, hideNavigation }) => {
  const [isVisible, inStickyRange] = useStickyHeader();
  const rootRef = useRef(null);

  return (
    <header
      className={cn(styles.root, {
        [styles.inStickyRange]: inStickyRange,
        [styles.sticky]: inStickyRange && isVisible,
        [styles.initialThemeDark]: initialTheme === 'dark',
      })}
      role="banner"
      ref={rootRef}
    >
      <Link
        prefetch={false}
        href="/"
        className={cn(styles.logo)}
        aria-label="Cloudsmith Homepage"
      >
        <Logo />
      </Link>
      {!hideNavigation && (
        <Navigation
          mainMenu={mainMenu}
          onMobileMenuOpen={() => {
            rootRef.current?.classList.add(styles.mobileMenuShowing);
          }}
          onMobileMenuClose={() => {
            rootRef.current?.classList.remove(styles.mobileMenuShowing);
          }}
          onSubmenuOpen={() => {
            rootRef.current?.classList.add(styles.secondaryNavigationShowing);
          }}
          onSubmenuClose={() => {
            rootRef.current?.classList.remove(
              styles.secondaryNavigationShowing,
            );
          }}
        />
      )}
    </header>
  );
};

export default Navbar;
