import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Navbar from '../Navbar';
import Footer from '../Footer';
import Banner from '../Banner';

import * as styles from './Layout.module.css';

const Layout = ({
  children,
  style,
  heroTheme,
  menu = {},
  navComponent = Navbar,
  className,
  hideNavigation = false,
}) => {
  const NavbarComp = navComponent;

  return (
    <>
      {menu.banner && menu.banner.showBanner && <Banner {...menu.banner} />}
      {menu.mainMenu && (
        <NavbarComp
          initialTheme={heroTheme}
          mainMenu={menu.mainMenu ?? []}
          hideNavigation={hideNavigation}
        />
      )}
      <main className={cn(styles.main, className)} style={style}>
        {children}
      </main>
      {menu.footerMenu && <Footer footerMenu={menu.footerMenu} />}
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.object,
  hasCallToAction: PropTypes.bool,
  hideNavigation: PropTypes.bool,
  menu: PropTypes.object,
  navComponent: PropTypes.elementType,
  className: PropTypes.string,
};

export default Layout;
